<template>
    <div class="login-wrap">
        <div class="ms-login">
            <div class="ms-title">家庭中医馆-消息管理后台</div>
            <el-form :model="param" :rules="rules" ref="login" label-width="0px" class="ms-content">
                <el-form-item prop="username">
                    <el-input v-model="param.username" placeholder="用户名">
                        <el-button slot="prepend" icon="el-icon-lx-people"></el-button>
                    </el-input>
                </el-form-item>
                <el-form-item prop="password">
                    <el-input
                        type="password"
                        v-model="param.password"
                         placeholder="密码"
                        @keyup.enter.native="submitForm()"
                    >
                        <el-button slot="prepend" icon="el-icon-lx-lock"></el-button>
                    </el-input>
                </el-form-item>
                <div class="login-btn">
                    <el-button type="primary" @click="submitForm()">登录</el-button>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>
import http from '../../api/manageApiConfig'
export default {
    data: function() {
        return {
            param: {
                username: '',
                password: '',
            },
            rules: {
                username: [{ required: true, message: '用户名不能为空', trigger: 'blur' }],
                password: [{ required: true, message: '密码不能为空', trigger: 'blur' }],
            },
            back_url:null
        };
    },
    created(){
       this.back_url = this.$route.query.back_url
        console.log(this.back_url)
    },
    methods: {
        submitForm() {
            let that = this;
            that.$refs.login.validate(valid => {
                if (valid) {
                    let params = {
                        username: that.param.username,
                        password: that.param.password
                    };
                    http.post("admin/login", params).then(function(res) {
                        if (res.data.status_code == 200) {
                            that.$message.success(`登录成功`);
                            localStorage.setItem('ms_username',that.param.username);
                            localStorage.setItem('ms_token',res.data.data.token);
                            localStorage.setItem('ms_nickname',res.data.data.user.nickname);
                            localStorage.setItem('ms_role',res.data.data.user.role_id);
                            localStorage.setItem('ms_id',res.data.data.user.id);
                            that.$router.push('/');
                        }else{
                            that.$message.error(res.data.msg);
                        }
                    });
                }
            });
        },
    },
};
</script>

<style scoped>
.login-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url(../../assets/img/login-bg.jpg);
    background-size: 100%;
}
.ms-title {
    width: 100%;
    line-height: 50px;
    text-align: center;
    font-size: 20px;
    color: #fff;
    border-bottom: 1px solid #ddd;
}
.ms-login {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 350px;
    margin: -190px 0 0 -175px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.3);
    overflow: hidden;
}
.ms-content {
    padding: 30px 30px;
}
.login-btn {
    text-align: center;
}
.login-btn button {
    width: 100%;
    height: 36px;
    margin-bottom: 10px;
}
.login-tips {
    font-size: 12px;
    line-height: 30px;
    color: #fff;
}
</style>